import React from "react";
import styled from "styled-components";

import Title from "./title";
import * as Utils from "../utils/utils";

const Article = styled.article`
  background: var(--clr-white);
  padding: 3rem 1.5rem;
  margin-bottom: 2rem;
  border-radius: var(--radius);
  text-align: center;
  transition: var(--transition);
  border: 1px solid #e5eff5;
  box-shadow: 0 2px 3px rgb(3 27 78 / 5%);

  p {
    transition: var(--transition);
  }

  h4: {
    font-weight: bold;
  }
`;

const Underline = styled.div`
  margin-bottom: 1.25rem;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
  width: 3rem;
  height: 0.12rem;
  transition: var(--transition);
`;

const ServicesCenter = styled.div`
  @media (min-width: 676px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Services = ({ servicesList, title, show }) => {
  return (
    <section className="section bg-grey">
      {!show && <Title title={title} />}
      <ServicesCenter className="section-center">
        {servicesList.map((service, i) => {
          const { title, text } = service;
          return (
            <Article key={i}>
              <h4>{Utils.firstCharUpperCase(title)}</h4>
              <Underline />
              {!show && <p>{text}</p>}
            </Article>
          );
        })}
      </ServicesCenter>
    </section>
  );
};

export default Services;
