import * as React from "react";
import { graphql } from "gatsby";
import Title from "../components/title";

import Hero from "../components/hero";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Blogs from "../components/blogs";
import Services from "../components/services";
import servicesList from "../constants/services-list";
import Card from "../components/ui/card";
import Subscribe from "../components/forms/subscribe";

const IndexPage = ({ data }) => {
  const {
    allContentfulBlogPost: { nodes: blogPosts },
    featuredPost,
  } = data;

  return (
    <Layout>
      <Seo title="Du contenu chrétien édifiant pour raffermir votre santé émotionnelle et spirituelle" />
      <Hero />
      <div
        style={{
          color: "white",
          background:
            "radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)",
        }}
      >
        <section
          className="section section-center"
          style={{ textAlign: "center" }}
        >
          <Title title="Notre devise" style={{ textAlign: "left" }} />
          <h2 style={{ marginBottom: "2rem" }}>
            Relève-toi.{" "}
            <span style={{ color: "var(--clr-primary-5)" }}>Rhabille-toi.</span>{" "}
            Reprends ta place.
          </h2>
          <p style={{ fontSize: "1.6rem", color: "white" }}>
            Réveille-toi, Sion, réveille-toi! […] Mets tes vêtements magnifiques
            […] Secoue la poussière qui te couvre. Lève-toi et reprends ta place
            […] Enlève les chaînes de ton cou, Sion, prisonnière!
          </p>
          <p>
            <small>Ésaïe 52 : 1-2</small>
          </p>
        </section>
      </div>
      <Services servicesList={servicesList} title="nos valeurs" />
      <section className="section-center" style={{ marginTop: "6rem" }}>
        <Card
          title={featuredPost.title}
          gatsbyImage={featuredPost.heroImage}
          description={featuredPost.description.description}
          url={`blog/${featuredPost.slug}`}
        />
      </section>
      <Blogs
        blogPosts={blogPosts}
        title="nos récents articles"
        showLink
        rootPath="/blog/"
      />
      <Subscribe />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      limit: 4
    ) {
      nodes {
        id
        title
        slug
        description {
          description
        }
        body {
          body
        }
        readTime
        publishDate(formatString: "DD.MM.YYYY", locale: "fr")
        categories
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 100
            height: 50
            resizingBehavior: FILL
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    featuredPost: contentfulBlogPost(featured: { eq: true }) {
      id
      title
      slug
      description {
        description
      }
      body {
        body
      }
      readTime
      publishDate(formatString: "DD.MM.YYYY", locale: "fr")
      categories
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          width: 100
          height: 50
          resizingBehavior: FILL
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;
