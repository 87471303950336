import React from "react"
import { FaCode, FaHandHolding, FaAndroid, FaHandsHelping } from "react-icons/fa"

const ServicesList = [
  {
    icon: <FaCode className="service-icon" />,
    title: "bienveillance",
    text: `Vous servir est notre priorité ; vous faire du bien, notre source de motivation.`,
  },
  {
    icon: <FaHandHolding className="service-icon" />,
    title: "confiance",
    text: `La confiance que vous nous témoignez est un cadeau inestimable que nous chérissons et gardons jalousement.`,
  },
  {
    icon: <FaAndroid className="service-icon" />,
    title: "compassion",
    text: `Peu importe votre passé, Veille sur ton cœur se veut un espace où toutes les femmes sont aimées inconditionnellement et sans jugement.`,
  },
  {
    icon: <FaHandsHelping className="service-icon" />,
    title: "passion",
    text: `L’enthousiasme incurable de notre équipe la pousse à constamment repousser ses limites pour nourrir notre vision.`,
  },
]

export default ServicesList
