import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Import react-slick css files
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Hero = () => {
  const {
    allContentfulBanner: { nodes: sliderImages }
  } = useStaticQuery(query);

  const sliderSettings = {
    dots: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
    // lazyLoad: true,
  };
  return (
    <HeroStyled>
      <SliderStyled {...sliderSettings}>
        {sliderImages.map((sliderImage, i) => {
          const image = getImage(sliderImage.image);
          const title = sliderImage.image.title;
          return <GatsbyImage image={image} alt={title} key={i} />;
        })}
      </SliderStyled>
    </HeroStyled>
  );
};

export default Hero;

const query = graphql`
  {
    allContentfulBanner(sort: { order: ASC, fields: createdAt }) {
      nodes {
        image {
          gatsbyImageData
          title
        }
      }
    }
  }
`;

const SliderStyled = styled(Slider)`
  .slick-dots {
    bottom: 10px;
  }
`;

// this style prevent slider to scroll horizontally
const HeroStyled = styled.div`
  overflow-x: hidden;
  background: radial-gradient(circle, rgb(238, 174, 202) 0%, rgb(148, 187, 233) 100%);
`;
